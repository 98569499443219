import React, { Component } from 'react';

const BreadcrumbOne = ({heading, home, title}) => {
    return (
        <section className="section breadcrumb-area d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/* Breamcrumb Content */}
                        <div className="breadcrumb-content d-flex flex-column align-items-center text-center">
                            <h3>{heading}</h3>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a className="text-uppercase" href="/">{home}</a></li>
                                <li className="breadcrumb-item active">{title}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BreadcrumbOne;