import React, { useEffect, useRef, useState } from "react";
import ReactFreezeframe from "react-freezeframe";

import creativity from "../../assets/home-json-icons/media/creativity.json";
import Originality from "../../assets/home-json-icons/media/originality.json";
import adaptability from "../../assets/home-json-icons/media/precision.json";
import simplicity from "../../assets/home-json-icons/media/simplicity.json";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import ease_of_use from "../../assets/home-json-icons/software/ease-of-use.json";
import Lottie from "lottie-react";
import { FaBullseye } from "react-icons/fa";
import { useLottie } from "lottie-react";

const Counter = () => {
  const [handleA, setHandleA] = useState(10);
  const [scrollval, setScrollVal] = useState(0);

  const handleHoverToAnimate = (index) => {
    setHandleA(index);
  };

  useEffect(() => {
    window.addEventListener("scroll", function () {
      let scrollValue = window.scrollY;
      setScrollVal(scrollValue);
    });
  }, []);

  const [animate, setAnimate] = useState(true);
  const [remove, setRemove] = useState(true);
  const [test, setTest] = useState(true);

  useEffect(() => {
    if (scrollval > 900) {
      setTimeout(() => {
        setTest(false);
      }, 3000);
    }
  }, []);

  var style1 = {
    width: "50px",
    height: "50px",
    margin: "auto",
    display: "block",
  };

  var style2 = {
    width: "50px",
    height: "50px",
    margin: "auto",
    display: "none",
  };

  const doSomething = () => {
    setTimeout(() => {
      setRemove(false);
    }, 2500);
  };

  return (
    <>
      <div className="counter-list" style={{ paddingTop: "1rem" }}>
        <ul>
          <li style={{ minHeight: "120px" }}>
            {/* Single Counter */}
            <div
              className="single-counter px-3 py-3 text-center"
              onMouseOver={() => handleHoverToAnimate(0)}
              onMouseLeave={() => handleHoverToAnimate(10)}
            >
              {/* Counter Icon */}
              <div className="counter-icon py-1" style={{ maxHeight: "80px" }}>
                {scrollval > 900 ? (
                  <>
                    <i style={{ maxHeight: "80px" }}>
                      <>
                        {remove && (
                          <Player
                            autoplay
                            src={Originality}
                            style={{
                              width: "50px",
                              height: "50px",
                              margin: "auto",
                            }}
                            onEvent={(event) => {
                              if (event === "complete") {
                                doSomething();
                              }
                            }}
                          ></Player>
                        )}
                        <Lottie
                          autoplay={scrollval > 900 ? false : true}
                          loop={handleA === 0 ? true : false}
                          animationData={Originality}
                          style={!remove ? style1 : style2}
                        />
                      </>
                    </i>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {/* Counter Text */}
              <div className="counter-text">
                <h5 style={{ marginTop: "0.5rem" }}>Originality</h5>
              </div>
            </div>
          </li>
          <li
            onMouseOver={() => handleHoverToAnimate(1)}
            onMouseLeave={() => handleHoverToAnimate(10)}
            style={{ minHeight: "120px" }}
          >
            {/* Single Counter */}
            <div className="single-counter px-3 py-3 text-center">
              {/* Counter Icon */}
              <div className="counter-icon py-1" style={{ maxHeight: "80px" }}>
                {scrollval > 900 ? (
                  <>
                    <i style={{ maxHeight: "80px" }}>
                      <>
                        {remove && (
                          <Player
                            autoplay
                            src={adaptability}
                            style={{
                              width: "50px",
                              height: "50px",
                              margin: "auto",
                            }}
                            onEvent={(event) => {
                              if (event === "complete") {
                                doSomething();
                              }
                            }}
                          ></Player>
                        )}
                        <Lottie
                          autoplay={scrollval > 900 ? false : true}
                          loop={handleA === 1 ? true : false}
                          animationData={adaptability}
                          style={!remove ? style1 : style2}
                        />
                      </>
                    </i>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {/* Counter Text */}
              <div className="counter-text">
                <h5 style={{ marginTop: "0.5rem" }}>Precision</h5>
              </div>
            </div>
          </li>
          <li
            onMouseOver={() => handleHoverToAnimate(2)}
            onMouseLeave={() => handleHoverToAnimate(10)}
            style={{ minHeight: "120px" }}
          >
            {/* Single Counter */}
            <div className="single-counter px-3 py-3 text-center">
              {/* Counter Icon */}
              <div className="counter-icon py-1" style={{ maxHeight: "80px" }}>
                {scrollval > 900 ? (
                  <>
                    <i style={{ maxHeight: "80px" }}>
                      <>
                        {remove && (
                          <Player
                            autoplay
                            src={ease_of_use}
                            style={{
                              width: "50px",
                              height: "50px",
                              margin: "auto",
                            }}
                            onEvent={(event) => {
                              if (event === "complete") {
                                doSomething();
                              }
                            }}
                          ></Player>
                        )}
                        <Lottie
                          autoplay={scrollval > 900 ? false : true}
                          loop={handleA === 2 ? true : false}
                          animationData={ease_of_use}
                          style={!remove ? style1 : style2}
                        />
                      </>
                    </i>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {/* Counter Text */}
              <div className="counter-text">
                <h5 style={{ marginTop: "0.5rem" }}>Result-oriented</h5>
              </div>
            </div>
          </li>
          <li
            onMouseOver={() => handleHoverToAnimate(3)}
            onMouseLeave={() => handleHoverToAnimate(10)}
            style={{ minHeight: "120px" }}
          >
            {/* Single Counter */}
            <div className="single-counter px-3 py-3 text-center">
              {/* Counter Icon */}
              <div className="counter-icon py-1" style={{ maxHeight: "80px" }}>
                {scrollval > 900 ? (
                  <>
                    <i style={{ maxHeight: "80px" }}>
                      <>
                        {remove && (
                          <Player
                            autoplay
                            src={simplicity}
                            style={{
                              width: "50px",
                              height: "50px",
                              margin: "auto",
                            }}
                            onEvent={(event) => {
                              if (event === "complete") {
                                doSomething();
                              }
                            }}
                          ></Player>
                        )}
                        <Lottie
                          autoplay={scrollval > 900 ? false : true}
                          loop={handleA === 3 ? true : false}
                          animationData={simplicity}
                          style={!remove ? style1 : style2}
                        />
                      </>
                    </i>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {/* Counter Text */}
              <div className="counter-text">
                <h5 style={{ marginTop: "0.5rem" }}>Creativity</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Counter;
