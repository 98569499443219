import React, { useEffect, useState } from "react";
import axios from "axios";
import whiteLogo from "../../assets/images/osgprologo.png";
import { AiFillInstagram, AiOutlineTwitter } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";
import { ReactComponent as FaceIcon } from "../../assets/footericons/facebook.svg";

import { ReactComponent as InstaIcon } from "../../assets/footericons/instagram.svg";

import { ReactComponent as LinkedinIcon } from "../../assets/footericons/linkedin.svg";

import { ReactComponent as TwitterIcon } from "../../assets/footericons/twitter.svg";

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/appo-json-2/themeOneFooterSection";

const Footer = () => {
  const [data, setData] = useState({});
  const [iconList, setIconList] = useState([]);
  const [footerList_1, setFooterList_1] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        setData(res.data);
        setIconList(res.data.iconList);
        setFooterList_1(res.data.footerList_1);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <footer className="section footer-area footer-bg">
      {/* Footer Top */}
      <div style={{ padding: "25px 0" }} className="footer-top">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-sm-6 col-lg-8">
              {/* Footer Items */}
              <div className="footer-items">
                {/* Logo */}
                <a className="navbar-brand" href="/">
                  <img className="logo" src={whiteLogo} alt="Logo" />
                </a>
                <p className="text-white-50 mt-2 mb-3">
                  When Businesses Go Viral
                </p>
                {/* Social Icons */}
                <div className="social-icons d-flex">
                  <a
                    style={{ background: "#131418" }}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                    href="https://www.facebook.com/OSGProtr/"
                  >
                    <FaceIcon style={{ width: "80%" }} />
                    {/* <FaFacebookSquare
                      style={{ color: "#ffffff", fontSize: "33px" }}
                    />
                    <FaFacebookSquare
                      style={{ color: "#ffffff", fontSize: "33px" }}
                    /> */}
                  </a>
                  <a
                    style={{ background: "#131418" }}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                    href="https://www.instagram.com/osgprotr/"
                  >
                    <InstaIcon style={{ width: "80%" }} />
                  </a>
                  <a
                    style={{ background: "#131418" }}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                    href="https://tr.linkedin.com/company/osgpro"
                  >
                    <LinkedinIcon style={{ width: "92%" }} />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              {/* Footer Items */}
              <div className="footer-items">
                {/* Footer Title */}
                <h3 className="footer-title text-white mb-2">
                  {data.linkText_1}
                </h3>
                <ul>
                  <li className="py-2">
                    <a className="text-white-50" href="/">
                      Home
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-white-50" href="/software">
                      Software Services
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-white-50" href="/media">
                      Media Services
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-white-50" href="/blog-two-column">
                      Blog
                    </a>
                  </li>
                  <li className="py-2">
                    <a className="text-white-50" href="/#contact">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Bottom */}
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Copyright Area */}
              <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                {/* Copyright Left */}
                <div className="copyright-left text-white-50">
                  &copy; Copyrights {new Date().getFullYear()} Osgpro. All
                  rights reserved.
                </div>
                {/* Copyright Right */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
