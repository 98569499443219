import React, { useEffect, useState } from "react";
import axios from "axios";
import ServiceAnimation from "../osgModifications/ServiceAnimation";
import ServiceAnimationCloud from "../osgModifications/ServiceAnimationCloud";
import Animation3 from "../osgModifications/Animation3";
import AnimationJS from "../osgModifications/AnimationJS";
import UserAnimation from "../osgModifications/UserAnimation";
import CounterSection from "../counterSection/mediaCounter";
import { Link } from "react-router-dom";

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/appo-json/themeOneAboutSection";

const AboutOne = () => {
  const [data, setData] = useState({});
  const [aboutData, setAboutData] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        setData(res.data);
        setAboutData(res.data.aboutData);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <section className="section about-app-area ptb_100">
        <div className="shapes-container">
          <div className="shape-1" />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6">
              {/* About Text */}
              <div className="about-text">
                {/* Headings */}
                <div className="headings d-flex align-items-center mb-4">
                  <span className="text-uppercase d-none d-sm-block">
                    Explore
                  </span>
                  <h2 className="text-capitalize">
                    Our Software Services and Capabilities
                  </h2>
                </div>
                <p className="my-3">
                  Let’s partner up to get your business online, whether you want
                  it for your customers or your enterprise. Our team of
                  engineers will build exactly what you need ensuring industry
                  standards and quality to give you and your team peace of mind.
                  Simple or complex, user or employee, local or cloud-based, our
                  team has got you covered.
                </p>
                {/* Store Buttons */}
                <Link
                  to="/software"
                  style={{
                    margin: "1rem 0",
                    padding: "0.5rem 2rem ",
                    fontWeight: "600",
                    border: "2px solid #444444",
                    borderRadius: "50px",
                  }}
                >
                  Learn More
                </Link>
                <div className="button-group store-buttons">
                  <CounterSection />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 service_section_css_style">
              {/* About Thumb */}
              <div className="about-thumb text-center d-lg-block" id="start_id">
                {/* <img src={data.aboutThumb} alt="" /> */}
                <ServiceAnimation />
                {/* <ServiceAnimationCloud /> */}
                {/* <Animation3 />*/}
                {/* <AnimationJS /> */}
                {/* <UserAnimation /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutOne;
